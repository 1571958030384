// const months = [
//   '',
//   'Enero',
//   'Febrero',
//   'Marzo',
//   'Abril',
//   'Mayo',
//   'Junio',
//   'Julio',
//   'Agosto',
//   'Septiembre',
//   'Octubre',
//   'Noviembre',
//   'Diciembre'
// ]
const months = [
  '',
  'ENE',
  'FE',
  'MAR',
  'ABR',
  'MAY',
  'JUN',
  'JUL',
  'AGO',
  'SEP',
  'OCT',
  'NOV',
  'DIC',
]

export const date = (str) => {

  const time = str.includes("T")

  if(time) {

    const arr = str.split('T')[0].split('-')

    const dd = Number(arr[2])
    const mm = Number(arr[1])
    const yy = Number(arr[0])

    const ret = `${dd} ${months[mm]}, ${yy}`

    const hour = str.split('T')[1]


    return {
      str: ret,
      dd,
      mm: months[mm],
      hour,
    }

  } else {

    const dates = str.split("-")

    let ret = {str: ''}

    for(const [i, str] of dates.entries()) {

      const arr = str.split("/")

      const dd = Number(arr[0])
      const mm = Number(arr[1])
      const yy = Number(arr[2])

      if( i > 0 ) ret.str += ` - `
      ret.str += `${dd} ${months[mm]}, ${yy}`

      if( i == 0 ) {
        ret.dd = dd
        ret.mm = months[mm]
      }
    }

    return ret
  }
}
