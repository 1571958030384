import   React            from "react"
import { useLocation }    from "@reach/router"

import { graphql,
         useStaticQuery,
         Link }           from "gatsby"

import { normalize } from "../../hooks/normalize"
import { date      } from "../../hooks/upcc-date"


export default () => {
  const home = (useLocation().pathname == '/') ? true : false

  let { allContentfulArteYCultura: { nodes: posts }, allContentful2023Noticia: { nodes: posts2 } } = useStaticQuery(
    graphql`
      query {
        allContentfulArteYCultura(limit: 4, filter: {node_locale: {eq: "en-US"}, activarPublicacion: {eq: true}, featured: {eq: true}}, sort: {fields: fechaDeOrden, order: DESC}) {
          nodes {
            titulo
            fechaDeOrden
            tituloDeRespaldo
            descripcion
            fecha
            imagenDeLaEntrada { file { url } }
          }
        },
        allContentful2023Noticia(filter: {node_locale: {eq: "en-US"}, featured: {eq: true}}) {
          nodes {
            title
            subtitle
            image { file { url } }
            description
            date
          }
        }
      }
    `);


  posts.forEach(e => {
    e.path = 'arteycultura'
  })

  posts2.forEach(e => {
    e.titulo            = e.title
    e.tituloDeRespaldo  = e.title
    e.descripcion       = e.subtitle
    e.imagenDeLaEntrada = e.image
    e.fecha             = e.date
    e.path              = 'noticias'
  })

  posts = posts.concat(posts2)

  return (
    <>
      <section class="wall">
        <div class="inner">

          <h1 class="upcc-h1 white">Noticias destacadas</h1>

          <div class="wall-col">

            <Link class="left" to={ `/${posts[0].path}/${ normalize(posts[0].titulo) }` }>
              <img class="upcc-img" src={ posts[0].imagenDeLaEntrada.file.url } />
              <span class="upcc-tag">NOTICIAS</span>
              <h5 class="upcc-h5 white">{ posts[0].tituloDeRespaldo } { posts[0].descripcion }</h5>
              <span class="upcc-date white">{ date(posts[0].fecha).str }</span>
            </Link>

            <div class="right">
              {
                posts.map(( post, i) => {
                  if( i > 0 ) {
                    return (
                      <Link class="wall-box" to={ `/${post.path}/${ normalize(post.titulo) }` }>
                        <div>
                          <span class="upcc-tag">NOTICIAS</span>
                          <h5 class="upcc-h5 white">{ post.tituloDeRespaldo } { post.descripcion }</h5>
                          <span class="upcc-date white">{ date(post.fecha).str }</span>
                        </div>
                        <img class="upcc-img small" src={ post.imagenDeLaEntrada.file.url } />
                      </Link>
                    )
                  }
                })
              }
            </div>

          </div>

          {home
            ? <Link className="more white center" to={`/noticias`}>VER MÁS<br/><i class="fa fa-angle-down"></i></Link>
            : <a class="more white center" href="#more">VER MÁS<br/><i class="fa fa-angle-down" id="more"></i></a>
          }
        </div>
      </section>
    </>
  )
}