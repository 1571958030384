import   React            from "react"
import { navigate }       from "gatsby"

export default ({ r }) => {

  function change(e) {
    if(e.target.value == 'all')         navigate("/noticias#more")
    if(e.target.value == 'audiovisual') navigate("/noticias/audiovisuales#more")
    if(e.target.value == 'performing')  navigate("/noticias/artes-escenicas#more")
    if(e.target.value == 'plastic')     navigate("/noticias/artes-plasticas#more")
    if(e.target.value == 'literature')  navigate("/noticias/literatura#more")
    if(e.target.value == 'visuals')     navigate("/noticias/artes-visuales#more")
    if(e.target.value == 'music')       navigate("/noticias/musica#more")
    if(e.target.value == 'patrimony')   navigate("/noticias/patrimonio#more")
    if(e.target.value == 'seal')        navigate("/noticias/sello-upc#more")
    if(e.target.value == 'about')       navigate("/noticias/quienes-somos#more")
    if(e.target.value == 'cultural')    navigate("/noticias/upc-cultural#more")
  }

  return (
    <>
      <section class="upcc-controls">
        <div class="left">
          <span class="bar"></span>
          <h5 class="upcc-h5">
            { r ==  0 && `Todas las Noticias`}
            { r ==  1 && `Artes Audiovisuales`}
            { r ==  2 && `Artes Escénicas`}
            { r ==  3 && `Artes Plásticas`}
            { r ==  4 && `Literatura`}
            { r ==  5 && `Artes Visuales`}
            { r ==  6 && `Música`}
            { r ==  7 && `Patrimonio`}
            { r == 10 && `Sello UPC`}
            { r == 11 && `Quiénes Somos`}
            { r == 12 && `UPC Cultural`}
          </h5>
        </div>
        <div class="right">
          <select class="upcc-select" onChange={ change }>
            <option value="all"         selected={ r ==  0 }> Todas las Noticias  </option>
            <option value="audiovisual" selected={ r ==  1 }> Artes Audiovisuales </option>
            <option value="performing"  selected={ r ==  2 }> Artes Escénicas     </option>
            <option value="plastic"     selected={ r ==  3 }> Artes Plásticas     </option>
            <option value="literature"  selected={ r ==  4 }> Literatura          </option>
            <option value="visuals"     selected={ r ==  5 }> Artes Visuales      </option>
            <option value="music"       selected={ r ==  6 }> Música              </option>
            <option value="patrimony"   selected={ r ==  7 }> Patrimonio          </option>
            <option value="seal"        selected={ r == 10 }> Sello UPC           </option>
            <option value="about"       selected={ r == 11 }> Quiénes Somos       </option>
            <option value="cultural"    selected={ r == 12 }> UPC Cultural        </option>
          </select>
        </div>
      </section>
    </>
  )
}