export const normalize = (str) => {
  var from = "ÃÀÁÄÂÈÉËÊÌÍÏÎÒÓÖÔÙÚÜÛãàáäâèéëêìíïîòóöôùúüûÑñÇç,#¿?",
    to = "AAAAAEEEEIIIIOOOOUUUUaaaaaeeeeiiiioooouuuunncc",
    mapping = {};

  for (var i = 0, j = from.length; i < j; i++)
    mapping[from.charAt(i)] = to.charAt(i);

  var ret = [];
  for (var x = 0, y = str.length; x < y; x++) {
    var c = str.charAt(x);
    if (mapping.hasOwnProperty(str.charAt(x)))
      ret.push(mapping[c]);
    else if ((str[x] === " "))
      ret.push('-');
    else if ((str.length - 1 === x) && (str[str.length - 1] === " "))
      ret.push('-');
    else
      ret.push(c);
  }
  return ret.join('').toLocaleLowerCase().trim();
};
